<template>
  <div>
    <div v-if="path == '/clientes/finish' || path == '/colaborador/finish'" :class="banner">
      <br>

    </div>

    <div class=" text-center footer">
      <br>

      <label class="cobertura">Cobertura entregada por
      </label>

      <br>

      <div class="text-center image">

        <v-img contain max-height="160px" :src="LogoSB" max-width="245" style=" margin: auto; " />

      </div>

    </div>

  </div>
</template>

<script>
import LogoSB from "@/assets/images/logo-blanco.png";

export default {
  name: "Footer",
  mounted() {
  },
  data: () => ({
    LogoSB,
    path: null,
    puntos: null,
    screen: null,
    banner: null,
    texto: null,
  }),
  mounted() {
    this.path = window.location.pathname;
    if (this.path == '/clientes/finish') {
      this.puntos = '7.000'
    } else if (this.path == '/colaborador/finish') {
      this.puntos = '10.000'
    }
    this.screen = window.screen.width
    if (this.screen > 800) {
      this.banner = 'text-center banner'
      this.texto = 'texto'
    } else {
      this.banner = 'text-center banner2'
      this.texto = 'texto2'
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.cobertura {
  text-align: center;

  letter-spacing: 0.3px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}

.detalle {
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  opacity: 1;
  font-family: 'Inter', sans-serif;

}

.footer {
  height: 222px;
  background: #4A4A4A 0% 0% no-repeat padding-box;
}

.banner {
  //height: 105%; 
  // background: #FFF;
}

.banner2 {
  height: 50%;
  // background: #0069B6;
}

.texto {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 40px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: initial;
}

.texto2 {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: "Scotia_Legal";
  font-size: 20px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: initial;
}

.butoon {
  width: 310px;
  height: 64px;
  background: #EF7C00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 30px Helvetica Neue;
  text-transform: initial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
}

.butoon2 {
  background: #EF7C00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 30px Helvetica Neue;
  text-transform: initial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.theme--light.v-btn.v-btn--has-bg {
  background: #EF7C00 0% 0% no-repeat padding-box;
}
.image{
  margin-top: 1%;
  margin-bottom: 1%;
}
</style>
