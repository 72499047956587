<template>
  <div :class="backgroundView">

    <br><br v-if="screen <= 800">
    <br><br v-if="screen <= 800">
    <br><br v-if="screen <= 800">

    <!-- <div :class="bannerView">BANNER SEGURO OBLIGATORIO SOAP 2024</div> -->

    <div :class="bannerView"></div>

    <br><br>

    <br v-if="screen <= 800">
    <br v-if="screen <= 800">

    <div :class="cartView">

      <CarritoCompra id="home_1" :patentes="arrayShoppingCart"></CarritoCompra>

    </div>

    <div v-if="servicesData.loadSoap" class="text-center">

      <br><br><br><br><br>

      <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>

      <br><br>

      Cargando

    </div>

    <div v-if="servicesData.loadSoap == false" :class="topView">

      <v-form class="text-center" ref="form" v-model="valid">

        <br><br>

        <div v-if="newCar == true">

          <div :class="titleNewCarView">
            <b :class="title1NewCarView">Agrega otro <span :class="title2NewCarView">vehículo</span></b>
          </div>

          <v-container :class="containerStyleView">
            <v-col :class="colView">
              <div style="max-width: 500px; width: 100%;">
                <label :class="patentLabelNewCarView">Ingresa tu patente</label>
                <v-text-field :class="patentFieldNewCarView" v-model="carData.patente" :rules="patenteRules"
                  maxlength="6" minlength="5" label="Ej: ABDC12" required solo @change="Mayus()"></v-text-field>
              </div>

              <br v-if="screen <= 800">

              <div style="max-width: 500px; width: 100%;">
                <label :class="mailLabelNewCarView">Ingresa tu correo</label>
                <v-text-field :class="mailFieldNewCarView" v-model="propietarioData.email" label="Ej: miemail@mail.com"
                  :rules="emailRules" @change="emailValidate()" required solo></v-text-field>
              </div>

            </v-col>
            <br><br>
          </v-container>

          <br>

          <div :class="captchaNewCarView">
            <vue-recaptcha ref="recaptcha" @verify="verifyMethod" sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
          </div>

          <br>

          <div :class="buttonsNewCarView" style="margin:auto;">

            <v-btn :class="btnBackToPayView" @click="btnBackToPay()">Volver al pago</v-btn>
            <br v-if="screen <= 800">
            <br v-if="screen <= 800">
            <v-btn :class="btnSearchNewCarView" @click="validate()">Buscar</v-btn>

          </div>

          <br>

          <!-- <v-btn v-if="patentes.length != 0" class="btnBack" @click="returnPago()">Volver al pago</v-btn> -->

        </div>

        <div v-else class="title">

          <div :class="titleView">

            <b :class="title1View">Vigente desde el</b>
            <b :class="title2View">1 de abril del 2024</b>

          </div>

          <v-container :class="containerStyleView">

            <v-col :class="colView">
              <div style="max-width: 500px; width: 100%;">
                <label :class="patentLabelView">Ingresa tu patente</label>
                <v-text-field :class="patentFieldView" v-model="carData.patente" :rules="patenteRules" maxlength="6"
                  minlength="5" label="Ej: ABDC12" hide-details="auto" required solo @change="Mayus()"></v-text-field>
              </div>

              <br v-if="screen <= 800">

              <div style="max-width: 500px; width: 100%;">
                <label :class="mailLabelView">Ingresa tu correo</label>
                <v-text-field :class="mailFieldView" v-model="propietarioData.email" label="Ej: miemail@mail.com"
                  :rules="emailRules" @change="emailValidate()" required solo></v-text-field>
              </div>

            </v-col>

            <br><br>

          </v-container>

          <br>

          <div :class="captchaView">
            <vue-recaptcha id="captcha_1" ref="recaptcha" @verify="verifyMethod"
              sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
          </div>

          <br>

          <v-btn :class="btnSearchView" id="avanza_1" @click="validate()">BUSCAR</v-btn>

          <br> <br v-if="screen <= 800">
          <div class="space"></div>


        </div>

      </v-form>

    </div>

    <br v-if="screen > 800">

    <div v-if="servicesData.loadSoap == false" class="sub">

      <!-- INICIO WEB -->

      <v-col v-if="screen > 800" class="text-center"
        style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center;  max-width: 80%; margin: auto;">

        <div v-if="servicesData.loadPlans == false" style="margin:auto ;" class="infoContenedor">

          <br>

          <div class="rectangulo-container">

            <div class="rectangulo">

              <br>

              <v-img contain :src="Auto" max-width="45" class="images" />

              <br>

              <label class="tituloLargo">
                {{ planData.soap.car.name }}
              </label>
            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.car.price) }}
              </label>

            </div>

          </div>

        </div>

        <br>

        <div v-if="servicesData.loadPlans == false" style="margin:auto;" class="infoContenedor">

          <br>

          <div class="rectangulo-container">

            <div class="rectangulo">

              <br>

              <v-img contain :src="Camioneta" max-width="45" class="images" />

              <br>

              <label class="tituloLargo">
                {{ planData.soap.camioneta.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.camioneta.price) }}
              </label>

            </div>

          </div>

        </div>

        <br>

        <div v-if="servicesData.loadPlans == false" style="margin:auto;" class="infoContenedor">

          <br>

          <div class="rectangulo-container">

            <div class="rectangulo">

              <br>

              <v-img contain :src="Moto" max-width="40" class="imagesMoto" />

              <br>

              <label class="titulo3Moto">
                {{ planData.soap.moto.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.moto.price) }}
              </label>

            </div>

          </div>

        </div>

        <br>

        <div v-if="servicesData.loadPlans == false" style="margin:auto;" class="infoContenedor">

          <br>

          <div class="rectangulo-container">

            <div class="rectangulo">

              <br>

              <v-img contain :src="Carro" max-width="35" class="images" />

              <br>

              <label class="tituloLargo">
                {{ planData.soap.arrastre.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.arrastre.price) }}
              </label>

            </div>

          </div>

        </div>

        <br>

        <div v-if="servicesData.loadPlans == false" style="margin:auto;" class="infoContenedor">
          <br>

          <div class="rectangulo-container">

            <div class="rectangulo">

              <br>

              <v-img contain :src="Minibus" max-width="40" class="imagesMiniBus" />

              <br>

              <label class="titulo3">
                {{ planData.soap.bus.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.bus.price) }}
              </label>

            </div>

          </div>

        </div>

      </v-col>


      <!-- Fin Web -->

      <!-- INICIO MOVIL -->
      <br v-if="screen <= 800">

      <v-col v-if="screen <= 800" class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

        <div v-if="servicesData.loadPlans == false" style="margin-left: -4%" :class="carBox">

          <br>

          <div class="rectangulo-container">

            <div class="rectanguloMobile">

              <br>

              <v-img contain :src="Auto" max-width="40" class="images2" />

              <br>

              <label class="tituloLargo2">
                {{ planData.soap.car.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.car.price) }}
              </label>

            </div>

          </div>

        </div>

        <div v-if="servicesData.loadPlans == false" style="margin-left:6%;margin-right:-4%" :class="carBox">

          <br>

          <div class="rectangulo-container">

            <div class="rectanguloMobile">

              <br>

              <v-img contain :src="Camioneta" max-width="40" class="images2" />

              <br>

              <label class="tituloLargo3">
                {{ planData.soap.camioneta.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.camioneta.price) }}
              </label>

            </div>

          </div>

        </div>

      </v-col>

      <br>

      <v-col v-if="screen <= 800" class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

        <div v-if="servicesData.loadPlans == false" style="margin-left: -4%" :class="carBox">
          <br>

          <div class="rectangulo-container">

            <div class="rectanguloMobile">

              <br>

              <v-img contain :src="Moto" max-width="40" class="images2" />

              <br>

              <label class="titulo2">
                {{ planData.soap.moto.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.moto.price) }}
              </label>

            </div>

          </div>

        </div>

        <div v-if="servicesData.loadPlans == false" style="margin-left:6%;margin-right:-4%" :class="carBox">

          <br>

          <div class="rectangulo-container">

            <div class="rectanguloMobile">

              <br>

              <v-img contain :src="Carro" max-width="40" class="images2" />

              <br>

              <label class="tituloLargo3">
                {{ planData.soap.arrastre.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.arrastre.price) }}
              </label>

            </div>

          </div>

        </div>

      </v-col>

      <br>

      <v-col v-if="screen <= 800" class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

        <div v-if="servicesData.loadPlans == false" style="margin:auto;" :class="carBox">

          <br>

          <div class="rectangulo-container">

            <div class="rectanguloMobile">

              <br>

              <v-img contain :src="Minibus" max-width="40" class="images2" />

              <br>

              <label class="titulo2">
                {{ planData.soap.bus.name }}
              </label>

            </div>

          </div>

          <br><br><br>

          <div class="subrectangulo-container">

            <div class="subrectangulo">

              <label class="subtitulo">
                {{ formatPrice(planData.soap.bus.price) }}
              </label>

            </div>

          </div>
        </div>

      </v-col>

      <!-- FIN MOVIL -->

      <v-col :class="btnFindSoapBox" class="text-center">

        <v-btn :class="btnFindSoapView" id="consulta_1" @click="redirectFindSoap()">CONSULTA TU SOAP</v-btn>

      </v-col>

      <br><br>

    </div>

  </div>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

import Auto from "@/assets/images/Automovil.png";
import Camioneta from "@/assets/images/Camioneta.png";
import Carro from "@/assets/images/Carro_de_arrastre.png";
import Minibus from "@/assets/images/minibus-2.png";
import Moto from "@/assets/images/Moto-2.png";

import ShieldService from "../services/shield.service";
import CarritoCompra from "@/components/Carrito.vue";

import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

export default {
  name: "InitForm",
  components: {
    VueRecaptcha,
    CarritoCompra,
  },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),

    backgroundView: null,
    bannerView: null,
    topView: null,
    title1View: null,
    title2View: null,
    cartView: null,
    containerStyleView: null,
    colView: null,
    patentFieldView: null,
    mailFieldView: null,
    patentLabelView: null,
    captchaView: null,
    btnSearchView: null,
    btnFindSoapView: null,
    carBox: null,
    titleView: null,
    btnBackToPayView: null,
    title2NewCarView: null,
    titleNewCarView: null,
    patentFieldNewCarView: null,
    mailFieldNewCarView: null,
    patentLabelNewCarView: null,
    mailLabelNewCarView: null,
    captchaNewCarView: null,
    title1NewCarView: null,
    btnSearchNewCarView: null,
    btnFindSoapBox: null,

    Auto,
    Camioneta,
    Carro,
    Minibus,
    Moto,

    captcha: null,

    screen: window.screen.width,

    arrayShoppingCart: [],

    servicesData: {
      loadSoap: false,
      loadPlans: false,

      keygen: null,
      token: null,
      priceTotal: 0,
      rutPropietario: null,
      e1: 1,
      typePlan: null,
      typeCliente: null,
    },

    carData: {
      id: null,
      patente: null,
      patenteDv: null,
      year: null,
      type: null,
      marca: null,
      model: null,
      motor: null,
      price: null,
      externalProductId: null,
    },

    propietarioData: {
      rut: null,
      nombres: null,
      firtlastname: null,
      secondlastname: null,
      email: null,
      phoneNumber: null,
      propietario: true,
      rutPropietario: null,
      remarque: null,
      pricePuntos: null,
      campana: null,
    },

    planData: {},

    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || "Ingrese un correo válido",
    ],
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
      (v) => (v && v.length <= 6) || 'La patente debe tener máximo 6 caracteres',
      (v) => (v && v.length > 4) || 'La patente debe tener mínimo 5 caracteres',
      (v) => /^[^\s]+$/.test(v) || 'La patente no puede contener espacios',
      (v) => /^[A-Za-z0-9]+$/.test(v) || 'La patente solo puede contener letras y números',
    ],
  }),
  methods: {
    btnBackToPay() {
      window.location.href = process.env.VUE_APP_DOMINIO + `${this.servicesData.typeCliente}/resumen`;
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      this.$router.push({ path: "/consulta" });
    },
    async validate() {
      const avanza_1 = document.getElementById('avanza_1');

      if (this.$refs.form.validate() && this.captcha == true) {

        this.servicesData.loadSoap = true;

        let largoPatente = this.carData.patente.length;

        if (largoPatente != 6) {
          var newPatente = this.carData.patente.match(/[a-z]+|[^a-z]+/gi)
          this.carData.patente = newPatente[0] + '0' + newPatente[1]
        }

        let verifyPatente = this.arrayShoppingCart.find(item => item.carData.patente === this.carData.patente);

        if (verifyPatente) {
          swal({
            title: "¡Patente duplicada!",
            text: "Ya ha ingresado esta patente al carrito de compra",
            icon: "info",
            button: "Ok",
          });
          this.servicesData.loadSoap = false;
        } else {

          let patenteAprobada = await ShieldService.consultasoapAprobado(this.carData.patente);

          if (patenteAprobada.status == 'ok') {
            swal({
              title: "¡Patente registrada!",
              text: "Esta patente ya cuenta con un seguro SOAP",
              icon: "info",
              button: "Ok",
            });
          } else {

            const carDataLocal = JSON.stringify(this.carData);
            const encryptedCarDataLocal = CryptoJS.AES.encrypt(carDataLocal, this.secretKey).toString();
            localStorage.setItem('carData', encryptedCarDataLocal);

            const propietarioDataLocal = JSON.stringify(this.propietarioData);
            const encryptedPropietarioDataLocal = CryptoJS.AES.encrypt(propietarioDataLocal, this.secretKey).toString();
            localStorage.setItem('propietarioData', encryptedPropietarioDataLocal);

            this.$router.push({ path: `/${this.servicesData.typeCliente}/datos` });
          }

          this.servicesData.loadSoap = false;

          this.servicesData.e1 = 1;

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          localStorage.setItem('servicesData', encryptedServicesDataLocal);
        }

      }
    },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    verifyMethod() {
      const captcha_1 = document.getElementById('captcha_1');
      this.captcha = true;
    },
    Mayus() {
      this.carData.patente = this.carData.patente.replace(/\s+/g, '');
      this.carData.patente = this.carData.patente.toUpperCase();
    },
    emailValidate() {
      this.propietarioData.email = this.propietarioData.email.replace(/\s+/g, '');
    },
    returnPago() {
      window.location.href = process.env.VUE_APP_DOMINIO + `${this.servicesData.typeCliente}/resumen`;
    },
    async getPlans() {

      this.servicesData.loadPlans = true;

      var shieldRes = await ShieldService.getPlans(this.servicesData.typePlan);

      if (shieldRes.status == 'ok') {

        this.planData = shieldRes.data[0];

        const planDataLocal = JSON.stringify(this.planData);
        const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
        localStorage.setItem('planData', encryptedPlanDataLocal);

      }

      this.servicesData.loadPlans = false;

    }
  },
  async mounted() {

    let path;
    let cliente;

    if (this.$route.path.includes('/no-clientes')) {
      path = 'soap-no-clientes-renta';
      cliente = 'no-clientes';
    } else if (this.$route.path.includes('/clientes')) {
      path = 'soap-clientes-renta';
      cliente = 'clientes';
    } else if (this.$route.path.includes('/colaboradores')) {
      path = 'soap-colaboradores-renta';
      cliente = 'colaboradores';
    } else if (this.$route.path.includes('/pyme')) {
      path = 'soap-pyme-renta';
      cliente = 'pyme';
    }

    const arrayShoppingCartAux = localStorage.getItem('arrayShoppingCart');
    const propietarioDataAux = localStorage.getItem('propietarioData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const carDataAux = localStorage.getItem('carData');
    const planDataAux = localStorage.getItem('planData');

    if (arrayShoppingCartAux) {
      const bytesArrayShoppingCart = CryptoJS.AES.decrypt(arrayShoppingCartAux, this.secretKey);
      const decryptedArrayShoppingCart = bytesArrayShoppingCart.toString(CryptoJS.enc.Utf8);
      this.arrayShoppingCart = JSON.parse(decryptedArrayShoppingCart);
      console.log("arrayShoppingCart: ", this.arrayShoppingCart);

    } else {
      const arrayShoppingCartLocal = JSON.stringify(this.arrayShoppingCart);
      const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
      localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);
    }

    if (propietarioDataAux) {
      const bytesPropietarioData = CryptoJS.AES.decrypt(propietarioDataAux, this.secretKey);
      const decryptedPropietarioData = bytesPropietarioData.toString(CryptoJS.enc.Utf8);
      this.propietarioData = JSON.parse(decryptedPropietarioData);
      console.log("propietarioData: ", this.propietarioData);

    } else {
      const propietarioDataLocal = JSON.stringify(this.propietarioData);
      const encryptedPropietarioDataLocal = CryptoJS.AES.encrypt(propietarioDataLocal, this.secretKey).toString();
      localStorage.setItem('propietarioData', encryptedPropietarioDataLocal);
    }

    if (servicesDataAux) {
      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);

      if (this.servicesData.typePlan != path) {
        localStorage.removeItem('carData');
        localStorage.removeItem('planData');
        localStorage.removeItem('propietarioData');
        localStorage.removeItem('servicesData');
        localStorage.removeItem('arrayShoppingCart');
        window.location.reload();
      }

    } else {
      this.servicesData.typePlan = path;
      this.servicesData.typeCliente = cliente;
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
    }

    if (carDataAux) {
      const bytesCarData = CryptoJS.AES.decrypt(carDataAux, this.secretKey);
      const decryptedCarData = bytesCarData.toString(CryptoJS.enc.Utf8);
      this.carData = JSON.parse(decryptedCarData);
      console.log("carData: ", this.carData);

    } else {
      const carDataLocal = JSON.stringify(this.carData);
      const encryptedCarDataLocal = CryptoJS.AES.encrypt(carDataLocal, this.secretKey).toString();
      localStorage.setItem('carData', encryptedCarDataLocal);
    }

    if (planDataAux) {
      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedPlanData = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedPlanData);
      console.log("planData: ", this.planData);

    } else {

      this.getPlans();

    }

    if (this.screen > 800) {

      this.backgroundView = 'backgroundView'
      this.bannerView = 'bannerView'
      this.topView = 'topView'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.cartView = 'cartView'
      this.containerStyleView = 'containerStyleView'
      this.colView = 'colView'
      this.patentFieldView = 'patentFieldView'
      this.mailFieldView = 'mailFieldView'
      this.patentLabelView = 'patentLabelView'
      this.mailLabelView = 'mailLabelView'
      this.captchaView = 'captchaView'
      this.btnSearchView = 'btnSearchView'
      this.carBox = 'carBox'
      this.btnFindSoapView = 'btnFindSoapView'
      this.titleView = 'titleView'
      this.btnBackToPayView = 'btnBackToPayView'
      this.title2NewCarView = 'title2NewCarView'
      this.titleNewCarView = 'titleNewCarView'
      this.patentFieldNewCarView = 'patentFieldNewCarView'
      this.mailFieldNewCarView = 'mailFieldNewCarView'
      this.patentLabelNewCarView = 'patentLabelNewCarView'
      this.mailLabelNewCarView = 'mailLabelNewCarView'
      this.captchaNewCarView = 'captchaNewCarView'
      this.buttonsNewCarView = 'buttonsNewCarView'
      this.title1NewCarView = 'title1NewCarView'
      this.btnSearchNewCarView = 'btnSearchNewCarView'
      this.btnFindSoapBox = 'btnFindSoapBox'

    } else if (this.screen <= 800) {

      this.backgroundView = 'backgroundView2'
      this.bannerView = 'bannerView2'
      this.topView = 'topView2'
      this.title1View = 'title1View2'
      this.title2View = 'title2View2'
      this.cartView = 'cartView2'
      this.containerStyleView = 'containerStyleView2'
      this.colView = 'colView2'
      this.patentFieldView = 'patentFieldView2'
      this.mailFieldView = 'mailFieldView2'
      this.patentLabelView = 'patentLabelView2'
      this.mailLabelView = 'mailLabelView2'
      this.captchaView = 'captchaView2'
      this.btnSearchView = 'btnSearchView2'
      this.carBox = 'carBox2'
      this.titleView = 'titleView2'
      this.btnBackToPayView = 'btnBackToPayView2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.title2NewCarView = 'title2NewCarView2'
      this.titleNewCarView = 'titleNewCarView2'
      this.patentFieldNewCarView = 'patentFieldNewCarView2'
      this.mailFieldNewCarView = 'mailFieldNewCarView2'
      this.patentLabelNewCarView = 'patentLabelNewCarView2'
      this.mailLabelNewCarView = 'mailLabelNewCarView2'
      this.captchaNewCarView = 'captchaNewCarView2'
      this.buttonsNewCarView = 'buttonsNewCarView2'
      this.title1NewCarView = 'title1NewCarView2'
      this.btnSearchNewCarView = 'btnSearchNewCarView2'
      this.btnFindSoapBox = 'btnFindSoapBox2'

    }
  },
};
</script>

<style lang="css" scoped>
.titleView {
  margin-top: -3.2%;
  margin-bottom: -2%;
}

/* .titleView2 {
  margin-bottom: -2%;
} */

.titleNewCarView {
  margin-top: -4.2%;
  margin-bottom: 0.4%;
}

.titleNewCarView2 {
  margin-top: 4%;
  margin-left: 18%;
  /* margin-top: -4.2%;
  margin-bottom: 0.4%; */
  margin-bottom: 4%;
}

.containerStyleView {
  justify-content: center;
  display: flex;
  margin-left: auto;
  padding-bottom: 3%;
  padding-top: -100%;
  max-width: 700px !important;
  width: 100% !important;
}

.containerStyleView2 {
  justify-content: center;
  display: block;
  margin: auto;
  /* margin-left: -14%; */

}

.obligatorio {
  text-align: center;
  font: normal normal normal 30px/36px Arial;
  letter-spacing: 0px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.vigencia {
  text-align: center;
  font: normal normal normal 15px/17px Arial;
  letter-spacing: 0px;
  color: #EF7C00;
  opacity: 1;
}

.topView {
  background: #FFF 0% 0% no-repeat padding-box !important;
  box-shadow: 7px 9px 9.9px 2px #00000029;
  opacity: 1;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-left: 23%;
  margin-top: -4%;
  margin-bottom: -0.2%;
}

.topView2 {
  background: #FFF 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.sub {
  background: #F3F3F3 0% 0% no-repeat padding-box;
  opacity: 1;
}

.titulo {
  text-align: left;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 16%;
  margin-left: 8%;
  width: 70%;
}

.titulo3 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 10%;
  margin-left: 9%;
  width: 70%;
}

.titulo3Moto {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 15%;
  margin-left: 9%;
  width: 70%;
}

.titulo2 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 14%;
  margin-left: 11%;
  width: 50%;
}

.tituloLargo {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 7%;
  margin-left: 9%;
  width: 55%;
}

.tituloLargo2 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 8%;
  margin-left: 9%;
  width: 50%;
}

.tituloLargo3 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 9%;
  margin-left: 9%;
  width: 60%;
}

.subtitulo {
  width: 102px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.96px;
  color: #4A4A4A;
  opacity: 1;
  font-weight: bold;
  margin-top: -2%;
}

.rectangulo-container {
  margin-top: -13%;
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.rectangulo-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8%;
  /* Altura del borde superior */
  background: linear-gradient(to right, #A74CF2, red);
  border-radius: 15px 15px 0 0;
}

.rectangulo {
  display: flex;
  margin-top: -2%;
  width: 180px;
  height: 73px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -1%;
  /* border-image: linear-gradient(to right, #ff8a00, #e52e71); */
}

.rectanguloMobile {
  display: flex;
  margin-top: -2%;
  width: 180px;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  /* margin-left: 3%; */
  /* border-image: linear-gradient(to right, #ff8a00, #e52e71); */
}

.subrectangulo-container {
  margin-top: -44%;
  position: relative;
  overflow: hidden;
  /* border-radius: 3% 3% 0 0; */
}

.subrectangulo-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 7%;
  /* Altura del borde superior */
  background: linear-gradient(to right, #A74CF2, red);
  /* border-radius: 15px 15px 0 0; */
}

.subrectangulo {
  width: 200;
  height: 55px;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  background-color: #FFFFFF;
  padding: 5%;
  margin-top: -3%;
}


.bannerView {
  font-weight: bold;
  text-align: center;
  margin-top: -17%;
  font-size: 46px;
  color: #FFFFFF;
}

.bannerView2 {
  font-weight: bold;
  text-align: center;
  margin-top: -43%;
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: -9%;
  padding-left: 2%;
  padding-right: 2%;
}

.title1View {
  font-family: 'Inter', sans-serif;
  color: #4A4A4A;
  font-weight: bold;
  font-size: 24px;

}

.title1View2 {
  font-family: 'Inter', sans-serif;
  color: #4A4A4A;
  font-weight: bold;
  font-size: 20px;
  margin-left: -40%;
}

.title2View {
  margin-left: 0.6%;
  font-family: 'Inter', sans-serif;
  color: red;
  font-weight: bold;
  font-size: 24px;
}

.title2View2 {
  margin-left: -35%;
  font-family: 'Inter', sans-serif;
  color: red;
  font-weight: bold;
  font-size: 20px;
  display: block;

}

.title1NewCarView {
  font-family: 'Inter', sans-serif;
  color: #4A4A4A;
  font-weight: bold;
  font-size: 24px;
}

.title1NewCarView2 {
  font-family: 'Inter', sans-serif;
  color: #4A4A4A;
  font-weight: bold;
  font-size: 20px;
  margin-left: -55%;
}

.title2NewCarView {
  margin-left: 1%;
  font-family: 'Inter', sans-serif;
  color: red;
  font-weight: bold;
  font-size: 24px;
}

.title2NewCarView2 {
  margin-left: 1%;
  font-family: 'Inter', sans-serif;
  color: red;
  font-weight: bold;
  font-size: 20px;
}

.patentLabelView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -62%;
  font-size: 70%;
}

.patentLabelView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 50%;
  font-size: 80%;
}

.mailLabelView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -62%;
  font-size: 70%;
}

.mailLabelView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 50%;
  font-size: 80%;
}

.cartView {
  margin-top: 8%;
  margin-left: -7%;
}

.cartView2 {
  margin-left: 48%;
  margin-top: 24%;
  margin-bottom: -23%;
}

.images {
  margin-left: 7%;
}

.imagesMiniBus {
  margin-left: 14%;
}

.imagesMoto {
  margin-left: 22%;
}

.images2 {
  margin-left: 8%;
  margin-top: 5%;
}

.captchaView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -5%;
  margin-bottom: -1%;
}

.captchaView2 {
  margin-top: -20%;
  margin-left: 7%;
  margin-bottom: 2%;
}

.btnSearchView {
  width: 300px;
  height: 40px !important;
  background: red 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin-top: -4%;
}

.btnSearchView2 {
  width: 300px;
  height: 50px !important;
  background: red 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin-left: 18% !important;
  margin-bottom: 8% !important;
}

.btnBack {
  width: 398px;
  height: 60px !important;
  background: #0069B6 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px !important;
  color: white;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

.btnFindSoapView {
  width: 284px;
  height: 40px !important;
  background: white 0% 0% no-repeat padding-box !important;
  border: 2px solid red;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.66px;
  color: black !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}


.btnFindSoapView2 {
  width: 284px;
  height: 50px !important;
  background: white 0% 0% no-repeat padding-box !important;
  border: 2px solid red;
  border-radius: 6px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.66px;
  color: black !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-top: 3%;
}

.carBox {
  box-shadow: 3px 3px 5px 0px #00000029;
  border-radius: 15px;
}

.carBox2 {
  box-shadow: 3px 3px 5px 0px #00000029;
  border-radius: 15px;
  width: 45%;
  /* margin-left: -1%; */
}

.backgroundView {
  background-color: #F3F3F3;
}

.backgroundView2 {
  background-color: #FFFFFF;
}

#app>div>main>div>div>div.topView>form {
  width: 100%;
}

.patentFieldView {
  width: 100%;
  /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
  text-transform: uppercase;

}

.patentFieldView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
  text-transform: uppercase;
}

.mailFieldView {
  width: 100%;
  /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
}

.mailFieldView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.btnBackToPayView {
  width: 100%;
  max-width: 300px;
  height: 40px !important;
  background: #4A4A4A 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-right: 5.8%;
  margin-left: 0.5%;
  font-size: 16px;
  margin-top: -4%;
}

.btnBackToPayView2 {
  width: 300px;
  height: 50px !important;
  background: #4A4A4A 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin: auto;
  /* margin-bottom: 20%; */
}

.colView {
  display: flex;
  gap: 10px;
}

.colView2 {
  display: block;
  margin: auto;
}

.patentFieldNewCarView {
  width: 100%;
  /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
  text-transform: uppercase;
}

.patentFieldNewCarView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 35%; */
  text-transform: uppercase;
}

.mailFieldNewCarView {
  width: 100%;
  /* O ajusta el porcentaje que desees */
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
}

.mailFieldNewCarView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 35%; */
}

.patentLabelNewCarView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-left: -60%;
  font-size: 88%;
}

.patentLabelNewCarView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 55%;
  font-size: 100%;
}

.mailLabelNewCarView {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 60%;
  font-size: 88%;
}

.mailLabelNewCarView2 {
  font-family: 'Inter', sans-serif;
  color: #333333;
  margin-right: 55%;
  font-size: 100%;
}

.captchaNewCarView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4%;
  margin-bottom: 1%;
}

.captchaNewCarView2 {
  margin-top: -15%;
  margin-left: 7%;
  margin-bottom: 0%;
}

.buttonsNewCarView {
  display: flex;
  gap: 10px;
  max-width: 80%;
  margin: auto;
}

.buttonsNewCarView2 {
  /* display:flex;  */
  margin-top: 2%;
}

.infoContenedor {
  width: 170px;
}

.space {
  /* margin-top: -3%; */
  margin-bottom: 2%;
}

.btnSearchNewCarView {
  width: 100%;
  max-width: 300px;
  height: 40px !important;
  background: red 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.btnSearchNewCarView2 {
  width: 300px;
  height: 50px !important;
  background: red 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
}

/* Sacar en caso de  */
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.backgroundView div.sub div.text-center.col button#consulta_1.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.btnFindSoapView {
  margin: auto !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  margin: auto !important;
}

.btnFindSoapBox {
  margin-top: -2%;
  margin-bottom: -1%;
}

.btnFindSoapBox2 {
  margin-top: 5%;
}
</style>