import { render, staticRenderFns } from "./Carrito.vue?vue&type=template&id=faa2e878&scoped=true&"
import script from "./Carrito.vue?vue&type=script&lang=js&"
export * from "./Carrito.vue?vue&type=script&lang=js&"
import style0 from "./Carrito.vue?vue&type=style&index=0&id=faa2e878&prod&lang=css&scoped=true&"
import style1 from "./Carrito.vue?vue&type=style&index=1&id=faa2e878&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faa2e878",
  null
  
)

export default component.exports