import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/no-clientes/inicio",
  },
  {
    path: "/colaboradores/inicio",
    name: "Colaboradors",
    component: Landing,
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/pyme/inicio",
    name: "Pymes",
    component: Landing,
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/no-clientes/inicio",
    name: "NoClientes",
    component: Landing,
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/clientes/inicio",
    name: "Clientess",
    component: Landing,
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/colaboradores/datos",
    name: "Colaborador",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/pyme/datos",
    name: "Pyme",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/no-clientes/datos",
    name: "NoCliente",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/clientes/datos",
    name: "Clientes",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/colaboradores/resumen",
    name: "Colaboradorss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/pyme/resumen",
    name: "Pymess",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/no-clientes/resumen",
    name: "NoClientess",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/clientes/resumen",
    name: "Clientesss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/colaboradores/finish",
    name: "Colaboradorsss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/pyme/finish",
    name: "Pymesss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/no-clientes/finish",
    name: "NoClientesss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/clientes/finish",
    name: "Clientessss",
    meta: {
      title: 'SOAP', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/consulta",
    name: "Consultar",
    meta: {
      title: 'SOAP RENTA', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP RENTA'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP RENTA'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ConsultaView.vue"),
  },
  {
    path: "/error",
    name: "Erorr",
    meta: {
      title: 'SOAP RENTA', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP RENTA'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP RENTA'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Error.vue"),
  },
  {
    path: "/info",
    name: "Info",
    meta: {
      title: 'SOAP RENTA', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'SOAP RENTA'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'SOAP RENTA'//open graph Descripcion metadata html
        }
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/ErrorNote.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
//... Cambiar todos los fronts por router mas avanzado
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
